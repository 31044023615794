import React from 'react';
import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import wrapDisplayName from 'recompose/wrapDisplayName';
import siteTheme from './theme';

// Apply some reset
const styles = theme => ({
  '@global': {
    html: {
      fontFamily: theme.typography.fontFamily,
      WebkitFontSmoothing: 'antialiased', // Antialiasing.
      MozOsxFontSmoothing: 'grayscale' // Antialiasing.
    },
    body: {
      margin: 0
    }
  }
});

let AppWrapper = props => props.children;

AppWrapper = withStyles(styles)(AppWrapper);

// const context = createThemeContext();

function withRoot(BaseComponent) {
  const WithRoot = props => (
    <ThemeProvider theme={siteTheme}>
      <AppWrapper>
        <BaseComponent {...props} />
      </AppWrapper>
    </ThemeProvider>
  );

  if (process.env.NODE_ENV !== 'production') {
    WithRoot.displayName = wrapDisplayName(BaseComponent, 'withRoot');
  }

  return WithRoot;
}

export default withRoot;
