import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import swReducer from './sw/reducers/sw';
import progressReducer from './progress/reducers/progress';
import toastsReducer from './toasts/reducers/toasts';
import authReducer from './auth/reducers/auth';
import passwordReducer from './auth/reducers/password';
import signupReducer from './auth/reducers/signup';
import userReducer from './user/reducers/user';
import dashboardReducer from './dashboard/reducers/dashboard';
import practiceReducer from './practices/reducers/practice';
import practiceHistoryReducer from './practices/reducers/practicehistory';
import practicepaperReducer from './practices/reducers/practicepaper';
import subjectReducer from './subjects/reducers/subjects';
// import practiceAanalytics from './practices/reducers/practiceanalytics';
import questionAanalytics from './practices/reducers/questionanalytics';
import reportIssue from './report/reducers/reportissue';
import testReducer from './tests/reducers/test';
import testSimulatorReducer from './tests/reducers/testsimulator';
import testHistoryReducer from './tests/reducers/testhistory';
import packageReducer from './packages/reducers/package';
import feedbackReducer from './feedbacks/reducers/feedback';
import notificationReducer from './notification/reducers/notification';
import wpblogReducer from './wpblog/reducers/wpblog';
import organisationReducer from './organisations/reducers/organisation';
import evalCodeReducer from './evalcode/reducers/Ide';
import skillReducer from './skills/reducers/skill';
import interestReducer from './interests/reducer/interests';
import publicUserResducer from './public/reducers/public';
import classReducer from './classes/reducers/classes';
import courseReducer from './courses/reducers/course';
import uiReducer from './ui/reducers/navigation';

const rootReducer = combineReducers({
  sw: swReducer,
  progress: progressReducer,
  toasts: toastsReducer,
  auth: authReducer,
  password: passwordReducer,
  signup: signupReducer,
  user: userReducer,
  dashboard: dashboardReducer,
  subjects: subjectReducer,
  practices: practiceReducer,
  practicehistory: practiceHistoryReducer,
  practicepaper: practicepaperReducer,
  tests: testReducer,
  testsimulator: testSimulatorReducer,
  testhistory: testHistoryReducer,
  codingassesment: evalCodeReducer,
  packages: packageReducer,
  form: formReducer,
  feedback: feedbackReducer,
  notification: notificationReducer,
  questionanalytics: questionAanalytics,
  reportissue: reportIssue,
  wpblog: wpblogReducer,
  organisation: organisationReducer,
  skill: skillReducer,
  interests: interestReducer,
  publicuser: publicUserResducer,
  classes: classReducer,
  course: courseReducer,
  ui: uiReducer
});

export default rootReducer;
