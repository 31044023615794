import {
  USER_PUBLIC_PROFILE_F_REQUEST,
  USER_PUBLIC_PROFILE_F_SUCCESS,
  USER_PUBLIC_PROFILE_F_FAILURE
} from '../constants';

const INITIAL_STATE = {
  isFetching: false,
  isError: false,
  errorMessage: '',
  user: null
};


export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
  case USER_PUBLIC_PROFILE_F_REQUEST:
    return {
      ...state,
      isFetching: true,
      isError: false,
      errorMessage: '',
      user: null
    };
  case USER_PUBLIC_PROFILE_F_SUCCESS:
    return {
      ...state,
      isFetching: false,
      isError: false,
      errorMessage: '',
      user: action.payload
    };
  case USER_PUBLIC_PROFILE_F_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    };
  default:
    return state;
  }
}
