export const PACKAGES_FETCH_REQUEST = 'packages_fetch_request';
export const PACKAGES_FETCH_SUCCESS = 'packages_fetch_success';
export const PACKAGES_FETCH_FAILURE = 'packages_fetch_failure';
export const PACKAGE_FETCH_REQUEST = 'package_fetch_request';
export const PACKAGE_FETCH_SUCCESS = 'package_fetch_success';
export const PACKAGE_FETCH_FAILURE = 'package_fetch_failure';

export const ACTIVATE_PACKAGE_REQUEST = 'activate_package_request';
export const ACTIVATE_PACKAGE_SUCCESS = 'activate_package_success';
export const ACTIVATE_PACKAGE_FAILURE = 'activate_package_failure';
