import {
  PACKAGES_FETCH_REQUEST,
  PACKAGES_FETCH_SUCCESS,
  PACKAGES_FETCH_FAILURE,
  PACKAGE_FETCH_REQUEST,
  PACKAGE_FETCH_SUCCESS,
  PACKAGE_FETCH_FAILURE,
  ACTIVATE_PACKAGE_REQUEST,
  ACTIVATE_PACKAGE_SUCCESS,
  ACTIVATE_PACKAGE_FAILURE
} from '../constants';

const INITIAL_STATE = {
  byId: null,
  allIds: [],
  selectedPackage: null,
  isFetching: false,
  isError: false,
  errorMessage: null,
  isActivating: false,
  isActivated: false,
  isActivationError: false,
  activationErrorMessage: ''
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
  case PACKAGES_FETCH_REQUEST:
    return {
      ...state,
      isFetching: true,
      isActivated: false,
      isError: false,
      errorMessage: null
    };
  case PACKAGES_FETCH_SUCCESS:
    return {
      ...state,
      isFetching: false,
      isError: false,
      allIds: action.payload.allIds,
      byId: action.payload.byId
    };
  case PACKAGES_FETCH_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    };
  case PACKAGE_FETCH_REQUEST:
    return {
      ...state,
      isFetching: true,
      isActivated: false,
      isError: false,
      errorMessage: null,
      selectedPackage: state.allIds.length > 0 ? state.byId[`${action.payload}`] : null
    };
  case PACKAGE_FETCH_SUCCESS:
    return {
      ...state,
      isFetching: false,
      selectedPackage: action.payload.data.data
    };
  case PACKAGE_FETCH_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    };
  case ACTIVATE_PACKAGE_REQUEST:
    return {
      ...state,
      isActivating: true,
      isActivated: false,
      isActivationError: false,
      activationErrorMessage: ''

    };
  case ACTIVATE_PACKAGE_SUCCESS:
    return {
      ...state,
      isActivating: false,
      isActivated: true,
      isActivationError: false,
      activationErrorMessage: ''

    };
  case ACTIVATE_PACKAGE_FAILURE:
    return {
      ...state,
      isActivating: false,
      isActivated: false,
      isActivationError: true,
      activationErrorMessage: action.payload
    };
  default:
    return state;
  }
}
