export const TESTS_FETCH_REQUEST = 'tests_fetch_request';
export const TESTS_FETCH_SUCCESS = 'tests_fetch_success';
export const TESTS_FETCH_FAILURE = 'tests_fetch_failure';
export const TEST_FETCH_REQUEST = 'test_fetch_request';
export const TEST_FETCH_SUCCESS = 'test_fetch_success';
export const TEST_FETCH_FAILURE = 'test_fetch_failure';
export const TEST_HISTORY_FETCH_REQUEST = 'test_history_fetch_request';
export const TEST_HISTORY_FETCH_SUCCESS = 'test_history_fetch_success';
export const TEST_HISTORY_FETCH_FAILURE = 'test_history_fetch_failure';
export const TEST_RESULT_FETCH_REQUEST = 'test_result_fetch_request';
export const TEST_RESULT_FETCH_SUCCESS = 'test_result_fetch_success';
export const TEST_RESULT_FETCH_FAILURE = 'test_result_fetch_failure';
export const TEST_ATTEMPT_FETCH_REQUEST = 'test_eligibility_fetch_request';
export const TEST_ATTEMPT_FETCH_SUCCESS = 'test_eligibility_fetch_success';
export const TEST_ATTEMPT_FETCH_FAILURE = 'test_eligibility_fetch_failure';
export const TEST_NOT_ATTEMPTED_EARLIER = 'test_not_attempted_earlier';
// New constants
export const START_TEST_REQUEST = 'start_test_request';
export const START_TEST_SUCCESS = 'start_test_success';
export const START_TEST_FAILURE = 'start_test_failure';
export const TEST_SESSION_FETCH_REQUEST = 'test_session_fetch_request';
export const TEST_SESSION_FETCH_SUCCESS = 'test_session_fetch_success';
export const TEST_SESSION_FETCH_FAILURE = 'test_session_fetch_failure';
export const TEST_QUESTION_UPDATE_REQUEST = 'test_question_update_request';
export const TEST_QUESTION_UPDATE_SUCCESS = 'test_question_update_success';
export const TEST_QUESTION_UPDATE_FAILURE = 'test_question_update_failure';
export const TEST_QUESTION_CLEAR_REQUEST = 'test_question_clear_request';
export const TEST_SUMMARY_FETCH_REQUEST = 'test_summary_fetch_request';
export const TEST_SUMMARY_FETCH_SUCCESS = 'test_summary_fetch_success';
export const TEST_SUMMARY_FETCH_FAILURE = 'test_summary_fetch_failure';
export const TEST_FINISH_REQUEST = 'test_finish_request';
export const TEST_FINISH_SUCCESS = 'test_finish_success';
export const TEST_FINISH_FAILURE = 'test_finish_failure';

export const UPLOAD_IMAGE_REQUEST = 'upload_image_request';
export const UPLOAD_IMAGE_SUCCESS = 'upload_image_success';
export const UPLOAD_IMAGE_FAILURE = 'upload_image_failure';
