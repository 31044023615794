import {
  PRACTICES_FETCH_REQUEST,
  PRACTICES_FETCH_SUCCESS,
  PRACTICES_FETCH_FAILURE,
  PRACTICE_FETCH_REQUEST,
  PRACTICE_FETCH_SUCCESS,
  PRACTICE_FETCH_FAILURE,
  PRACTICE_ANALYTICS_FETCH_REQUEST,
  PRACTICE_ANALYTICS_FETCH_SUCCESS,
  PRACTICE_ANALYTICS_FETCH_FAILURE
} from '../constants';

const INITIAL_STATE = {
  byId: null,
  allIds: [],
  selectedPractice: null,
  selectedPracticeAnalytics: null,
  isFetching: false,
  isFetchingAnalytics: false,
  isError: false,
  errorMessage: null,
  activePractices: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case PRACTICES_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: null
      };
    case PRACTICES_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        allIds: action.payload.allIds,
        byId: action.payload.byId,
        activePractices: action.payload.activePractices
      };
    case PRACTICES_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    case PRACTICE_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: null,
        // selectedPractice: state.allIds.length > 0 ? state.byId[`${action.payload}`] : null
      };
    case PRACTICE_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        selectedPractice: action.payload.data.data
      };
    case PRACTICE_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    case PRACTICE_ANALYTICS_FETCH_REQUEST:
      return {
        ...state,
        isFetchingAnalytics: true,
        selectedPracticeAnalytics: null
      };
    case PRACTICE_ANALYTICS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAnalytics: false,
        selectedPracticeAnalytics: action.payload.data
      };
    case PRACTICE_ANALYTICS_FETCH_FAILURE:
      return {
        ...state,
        isFetchingAnalytics: false,
        selectedPracticeAnalytics: null
      };
    default:
      return state;
  }
}
