import {
  ORGANISATIONS_FETCH_REQUEST,
  ORGANISATIONS_FETCH_SUCCESS,
  ORGANISATIONS_FETCH_FAILURE
} from '../constants';


const INITIAL_STATE = {
  organisation: null,
  isFetching: false,
  isError: false,
  errorMessage: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
  case ORGANISATIONS_FETCH_REQUEST:
    return {
      ...state,
      isFetching: true
    };
  case ORGANISATIONS_FETCH_SUCCESS:
    return {
      ...state,
      isFetching: false,
      organisation: action.payload.data
    };
  case ORGANISATIONS_FETCH_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    };
  default:
    return state;
  }
}
