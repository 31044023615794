import {
  TESTS_FETCH_REQUEST,
  TESTS_FETCH_SUCCESS,
  TESTS_FETCH_FAILURE,
  TEST_FETCH_REQUEST,
  TEST_FETCH_SUCCESS,
  TEST_FETCH_FAILURE
} from '../constants';

const INITIAL_STATE = {
  byId: null,
  allIds: [],
  selectedTest: null,
  isFetching: false,
  isError: false,
  errorMessage: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
  case TESTS_FETCH_REQUEST:
    return {
      ...state,
      isFetching: true,
      isError: false,
      errorMessage: null
    };
  case TESTS_FETCH_SUCCESS:
    return {
      ...state,
      isFetching: false,
      isError: false,
      allIds: action.payload.allIds,
      byId: action.payload.byId
    };
  case TESTS_FETCH_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    };
  case TEST_FETCH_REQUEST:
    return {
      ...state,
      isFetching: true,
      isError: false,
      errorMessage: null,
      selectedTest: state.allIds.length > 0 ? state.byId[`${action.payload}`] : null
    };
  case TEST_FETCH_SUCCESS:
    return {
      ...state,
      isFetching: false,
      isError: false,
      selectedTest: action.payload.data.data
    };
  case TEST_FETCH_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    };
  default:
    return state;
  }
}
