import {
  FORGOT_PASSWORD_GENERATE_OTP_REQUEST,
  FORGOT_PASSWORD_GENERATE_OTP_SUCCESS,
  FORGOT_PASSWORD_GENERATE_OTP_FAILURE,
  FORGOT_PASSWORD_RESEND_OTP_REQUEST,
  FORGOT_PASSWORD_RESEND_OTP_SUCCESS,
  FORGOT_PASSWORD_RESEND_OTP_FAILURE,
  FORGOT_PASSWORD_VERIFY_OTP_REQUEST,
  FORGOT_PASSWORD_VERIFY_OTP_SUCCESS,
  FORGOT_PASSWORD_VERIFY_OTP_FAILURE,
  FORGOT_PASSWORD_SET_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SET_PASSWORD_FAILURE
} from '../constants';

const INITIAL_STATE = {
  otpGenerated: false,
  otpResent: false,
  otpVerified: false,
  user: {},
  isError: false,
  isFetching: false,
  errorMessage: ''
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
  case FORGOT_PASSWORD_GENERATE_OTP_REQUEST:
    return {
      ...state,
      otpGenerated: false,
      user: {},
      isFetching: true,
      isError: false
    };
  case FORGOT_PASSWORD_GENERATE_OTP_SUCCESS:
    return {
      ...state,
      otpGenerated: true,
      isFetching: false,
      isError: false,
      errorMessage: '',
      user: action.payload.data.user
    };
  case FORGOT_PASSWORD_GENERATE_OTP_FAILURE:
    return {
      ...state,
      otpGenerated: false,
      isFetching: false,
      isError: true,
      user: {},
      errorMessage: action.payload
    };
  case FORGOT_PASSWORD_RESEND_OTP_REQUEST:
    return {
      ...state,
      isFetching: true,
      isError: false,
      errorMessage: ''
    };
  case FORGOT_PASSWORD_RESEND_OTP_SUCCESS:
    return {
      ...state,
      isFetching: false,
      isError: false,
      errorMessage: '',
      user: action.payload.data.user
    };
  case FORGOT_PASSWORD_RESEND_OTP_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    };
  case FORGOT_PASSWORD_VERIFY_OTP_REQUEST:
    return {
      ...state,
      isFetching: true,
      isError: false
    };
  case FORGOT_PASSWORD_VERIFY_OTP_SUCCESS:
    return {
      ...state,
      otpVerified: true,
      isFetching: false,
      isError: false,
      errorMessage: '',
      user: action.payload.data.user
    };
  case FORGOT_PASSWORD_VERIFY_OTP_FAILURE:
    return {
      ...state,
      otpVerified: false,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    };
  case FORGOT_PASSWORD_SET_PASSWORD_REQUEST:
    return {
      ...state,
      isFetching: true,
      isError: false
    };
  case FORGOT_PASSWORD_SET_PASSWORD_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    };
  default:
    return state;
  }
}
