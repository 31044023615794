import {
  FETCH_MEMBER_COURSES_REQUEST,
  FETCH_MEMBER_COURSES_SUCCESS,
  FETCH_MEMBER_COURSES_FAILURE,
  FETCH_MEMBER_COURSE_REQUEST,
  FETCH_MEMBER_COURSE_SUCCESS,
  FETCH_MEMBER_COURSE_FAILURE
} from '../constants';

const INITIAL_STATE = {
  byId: null,
  allIds: [],
  selected: {},
  isFetching: false,
  isError: false,
  errorMessage: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_MEMBER_COURSES_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: null
      };
    case FETCH_MEMBER_COURSES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        allIds: action.payload.allIds,
        byId: action.payload.byId
      };
    case FETCH_MEMBER_COURSES_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    case FETCH_MEMBER_COURSE_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: null
      };
    case FETCH_MEMBER_COURSE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        selected: action.payload
      };
    case FETCH_MEMBER_COURSE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    default:
      return state;
  }
}
