import {
  START_TEST_REQUEST,
  START_TEST_SUCCESS,
  START_TEST_FAILURE,
  TEST_SESSION_FETCH_REQUEST,
  TEST_SESSION_FETCH_SUCCESS,
  TEST_SESSION_FETCH_FAILURE,
  TEST_QUESTION_UPDATE_REQUEST,
  TEST_QUESTION_CLEAR_REQUEST,
  TEST_FINISH_REQUEST,
  TEST_FINISH_SUCCESS,
  TEST_FINISH_FAILURE,
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILURE
} from '../constants';

const INITIAL_STATE = {
  session: null,
  currentSection: null,
  sectionStats: null,
  currentQuestion: null,
  nextQuestion: null,
  isFetching: false,
  isError: false,
  errorMessage: null,
  submittedSession: null,
  allSectionWithQue: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case START_TEST_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: null,
        submittedSession: null
      };
    case START_TEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        errorMessage: null,
        submittedSession: null
      };
    case START_TEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    case TEST_SESSION_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: null,
      };
    case TEST_SESSION_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        errorMessage: null,
        session: action.payload.session,
        currentSection: action.payload.currentSection,
        currentQuestion: action.payload.currentQuestion,
        nextQuestion: action.payload.nextQuestion,
        sectionStats: action.payload.sectionStats,
        allSectionWithQue: action.payload.allSectionWithQue
      };
    case TEST_SESSION_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    case TEST_QUESTION_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: null,
        currentQuestion: state.nextQuestion,
        nextQuestion: null
      };
    case TEST_QUESTION_CLEAR_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: null
      };
    case TEST_FINISH_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: null
      };
    case TEST_FINISH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        errorMessage: null,
        session: null,
        currentSection: null,
        currentQuestion: null,
        nextQuestion: null,
        sectionStats: null,
        allSectionWithQue: null,
        submittedSession: action.payload.data
      };
    case TEST_FINISH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    case UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    default:
      return state;
  }
}
