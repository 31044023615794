import React, { lazy } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Media from 'react-media';
// Relative imports
import withRoot from './routes/shared/hoc/withRoot';
import { SMALL_SCREEN } from './routes/shared/constants/Theme';
import Progress from './routes/shared/containers/Progress';
import Toasts from './routes/shared/containers/ToastsContainer';
import withSuspense from './routes/shared/hoc/withSuspense';
// Lazy loading components
const PwaWelcome = lazy(() => import(/* webpackChunkName: "PWA_Welcome" */ './routes/pwa_welcome'));
const Signin = lazy(() => import(/* webpackChunkName: "Signin" */ './routes/signin'));
const Signup = lazy(() => import(/* webpackChunkName: "Signup" */ './routes/signup'));
const SignupQuickly = lazy(() =>
  import(/* webpackChunkName: "Signup" */ './routes/signup_quickly')
);
const ForgotPwd = lazy(() =>
  import(/* webpackChunkName: "ForgotPwd" */ './routes/forgot_password')
);
const AcceptInvitation = lazy(() =>
  import(/* webpackChunkName: "AcceptInvitation" */ './routes/accept_invitation')
);
const ActivateMembership = lazy(() =>
  import(/* webpackChunkName: "ActivateMembership" */ './routes/activate_membership')
);
const Signout = lazy(() => import(/* webpackChunkName: "Signout" */ './routes/signout'));
const GetStarted = lazy(() => import(/* webpackChunkName: "GetStarted" */ './routes/get_started'));
const Memberships = lazy(() =>
  import(/* webpackChunkName: "Memberships" */ './routes/memberships')
);
const Account = lazy(() => import(/* webpackChunkName: "Account" */ './routes/account'));
const PwaAccount = lazy(() => import(/* webpackChunkName: "PWA_Account" */ './routes/pwa_account'));
const RemoteAssessmentUri = lazy(() =>
  import(/* webpackChunkName: "RemoteAssessmentUri" */ './routes/remote_assessment_uri')
);
const RemotePackageUri = lazy(() =>
  import(/* webpackChunkName: "RemotePackageUri" */ './routes/remote_package_uri')
);
// const ResetPassword = lazy(() =>
//   import(/* webpackChunkName: "ResetPassword" */ './routes/reset_password')
// );
// const PracticeRoom = lazy(() =>
//   import(/* webpackChunkName: "PracticeRoom" */ './routes/practice_room')
// );
// const TestRoom = lazy(() => import(/* webpackChunkName: "TestRoom" */ './routes/test_room'));
// const Summary = lazy(() => import(/* webpackChunkName: "Summary" */ './routes/summary'));
// const PublicProfile = lazy(() =>
//   import(/* webpackChunkName: "publicprofile" */ './routes/public_profile_view')
// );

// Protect private routes
const PrivateRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      authenticated ? <Component {...props} /> : <Redirect to={{ pathname: '/signin' }} />
    }
  />
);
PrivateRoute.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired
};

// Redirect public routes
const PublicRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !authenticated ? (
        <Component {...props} />
      ) : (
          <Redirect to={{ pathname: '/account/dashboard' }} />
        )
    }
  />
);
PublicRoute.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired
};

function App(props) {
  const { authenticated, isServiceWorkerUpdated, serviceWorkerRegistration } = props;

  if (isServiceWorkerUpdated) {
    console.log('SW: There is a new version available, auto updating app');
    const registrationWaiting = serviceWorkerRegistration;
    if (registrationWaiting) {
      console.log('SW: SKIP_WAITING');
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.addEventListener('statechange', e => {
        console.log('SW: statechange');
        if (e.target.state === 'activated') {
          console.log('SW: activated');
          window.location.reload();
        }
      });
    }
  }

  return (
    <>
      <Router>
        <>
          <Progress />
          <Toasts />
          <Media query={SMALL_SCREEN}>
            {isSmallScreen =>
              isSmallScreen ? (
                <PublicRoute
                  exact
                  path="/"
                  component={withSuspense(PwaWelcome)}
                  authenticated={authenticated}
                />
              ) : (
                  <PublicRoute
                    exact
                    path="/"
                    component={withSuspense(Signin)}
                    authenticated={authenticated}
                  />
                )
            }
          </Media>
          <PublicRoute
            path="/signin"
            component={withSuspense(Signin)}
            authenticated={authenticated}
          />
          <PublicRoute
            path="/signup"
            component={withSuspense(Signup)}
            //component={withSuspense(SignupQuickly)}
            authenticated={authenticated}
          />
          <PublicRoute
            path="/join"
            component={withSuspense(SignupQuickly)}
            authenticated={authenticated}
          />
          <PublicRoute
            path="/forgot-password"
            component={withSuspense(ForgotPwd)}
            authenticated={authenticated}
          />
          <PublicRoute
            path="/accept_invitation/:token"
            component={withSuspense(AcceptInvitation)}
            authenticated={authenticated}
          />
          <Route path="/activate_membership/:token" component={withSuspense(ActivateMembership)} />
          <Route path="/signout" component={withSuspense(Signout)} />
          <Route
            path="/remote-assessment-uri/:organisationId/:assessmentType/:assessmentID"
            component={withSuspense(RemoteAssessmentUri)}
          />
          <Route
            path="/remote-package-uri/:organisationId/packages/:packageID"
            component={withSuspense(RemotePackageUri)}
          />
          <PrivateRoute
            path="/get-started"
            component={withSuspense(GetStarted)}
            authenticated={authenticated}
          />
          <PrivateRoute
            path="/memberships"
            component={withSuspense(Memberships)}
            authenticated={authenticated}
          />
          <Media query={SMALL_SCREEN}>
            {isSmallScreen =>
              isSmallScreen ? (
                <PrivateRoute
                  path="/account"
                  component={withSuspense(PwaAccount)}
                  authenticated={authenticated}
                />
              ) : (
                  <PrivateRoute
                    path="/account"
                    component={withSuspense(Account)}
                    authenticated={authenticated}
                  />
                )
            }
          </Media>
          {/* <PublicRoute
            path="/reset-password/:token"
            component={withSuspense(ResetPassword)}
            authenticated={authenticated}
          /> */}
          {/* <PrivateRoute
            path="/account/test-room"
            component={withSuspense(TestRoom)}
            authenticated={authenticated}
          /> */}
          {/* <PrivateRoute
            path="/practice-room"
            component={withSuspense(PracticeRoom)}
            authenticated={authenticated}
          /> */}

          {/* <Route path="/summary/:id" component={withSuspense(Summary)} authenticated={authenticated} /> */}
          {/* <Route path="/resume/:username" component={withSuspense(PublicProfile)} /> */}
        </>
      </Router>
    </>
  );
}

App.defaultProps = { authenticated: false };
App.propTypes = {
  authenticated: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    isServiceWorkerUpdated: state.sw.serviceWorkerUpdated,
    serviceWorkerRegistration: state.sw.serviceWorkerRegistration
  };
}
export default connect(mapStateToProps, null)(withRoot(App));
