export const FETCH_USER_INTERESTS_REQUEST = 'fetch_user_interests_request';
export const FETCH_USER_INTERESTS_SUCCESS = 'fetch_user_interests_success';
export const FETCH_USER_INTERESTS_FAILURE = 'fetch_user_interests_failure';

export const ADD_NEW_INTEREST_REQUEST = 'add_new_interest_request';
export const ADD_NEW_INTEREST_SUCCESS = 'add_new_interest_success';
export const ADD_NEW_INTEREST_FAILURE = 'add_new_interest_failure';

export const CLEAR_INTERESTS = 'clear_interests';

export const CLEAR_ERROR = 'clear_error';
