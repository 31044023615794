import {
  NOTIFICATION_FETCH_REQUEST,
  NOTIFICATION_FETCH_SUCCESS,
  NOTIFICATION_FETCH_FAILURE,
  NOTIFICATION_UPDATE_REQUEST,
  NOTIFICATION_UPDATE_SUCCESS,
  NOTIFICATION_UPDATE_FAILURE,
  SHOW_NEW_NOTIFICATION,
  HIDE_NEW_NOTIFICATION
} from '../constants';


const INITIAL_STATE = {
  byId: {},
  allIds: [],
  isNewAvaiable: false,
  newNotification: null,
  isFetching: false,
  isError: false,
  errorMessage: null,
  count: 0
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
  case NOTIFICATION_FETCH_REQUEST:
    return {
      ...state,
      isFetching: true
    };
  case NOTIFICATION_FETCH_SUCCESS:
    return {
      ...state,
      isFetching: false,
      byId: action.payload.byId,
      count: action.payload.unread_count,
      allIds: action.payload.allIds
    };
  case NOTIFICATION_FETCH_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    };
  case NOTIFICATION_UPDATE_REQUEST:
    return {
      ...state,
      isFetching: true
    };
  case NOTIFICATION_UPDATE_SUCCESS:
    return {
      ...state,
      isFetching: false,
      count: action.payload.unread_count,
      byId: {
        ...state.byId,
        [action.payload.data.notification_id]: {
          ...state.byId[action.payload.data.notification_id],
          is_read: action.payload.data.is_read
        }
      }
    };
  case NOTIFICATION_UPDATE_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    };
  case SHOW_NEW_NOTIFICATION:
    return {
      ...state,
      isNewAvaiable: true,
      newNotification: action.payload
    };
  case HIDE_NEW_NOTIFICATION:
    return {
      ...state,
      isNewAvaiable: false,
      newNotification: null
    };
  default:
    return state;
  }
}
