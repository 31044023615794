import {
  PROGRESS_START,
  PROGRESS_STOP
} from '../constants';

const INITIAL_STATE = {
  isActive: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
  case PROGRESS_START:
    return {
      ...state,
      isActive: true
    };
  case PROGRESS_STOP:
    return {
      ...state,
      isActive: false
    };
  default:
    return state;
  }
}
