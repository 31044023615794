import {
  FEED_FETCH_REQUEST,
  FEED_FETCH_SUCCESS,
  FEED_FETCH_FAILURE,
  USER_DAILY_STATS_REQUEST,
  USER_DAILY_STATS_SUCCESS,
  USER_DAILY_STATS_FAILURE

} from '../constants';

const INITIAL_STATE = {
  feeds: {},
  isFetching: false,
  isError: false,
  errorMessage: null,
  isDSFetching: false,
  isDSError: false,
  dsErrorMessage: '',
  dailyStats: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
  case FEED_FETCH_REQUEST:
    return {
      ...state,
      isFetching: true,
      isError: false,
      errorMessage: null
    };
  case FEED_FETCH_SUCCESS:
    return {
      ...state,
      isFetching: false,
      isError: false,
      feeds: action.payload
    };
  case FEED_FETCH_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    };
  case USER_DAILY_STATS_REQUEST:
    return {
      ...state,
      isDSFetching: true,
      isDSError: false,
      dsErrorMessage: '',
      dailyStats: []
    };
  case USER_DAILY_STATS_SUCCESS:
    return {
      ...state,
      isDSFetching: false,
      isDSError: false,
      dsErrorMessage: '',
      dailyStats: action.payload
    };
  case USER_DAILY_STATS_FAILURE:
    return {
      ...state,
      isDSFetching: false,
      isDSError: true,
      dsErrorMessage: action.payload,
      dailyStats: []
    };
  default:
    return state;
  }
}
