import {
    FETCH_ALL_CLASSES_REQUEST,
    FETCH_ALL_CLASSES_SUCCESS,
    FETCH_ALL_CLASSES_FAILURE,
    FETCH_ALL_CLASS_DETAILS_REQUEST,
    FETCH_ALL_CLASS_DETAILS_SUCCESS,
    FETCH_ALL_CLASS_DETAILS_FAILURE
} from '../constants';


const INITIAL_STATE = {
  classList: null,
  ongoing: [],
  upcoming: [],
  isFetching: false,
  isError: false,
  errorMessage: null,
  classDetails: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_CLASSES_REQUEST:
        return {
            ...state,
            isFetching: true,
            isError: false,
            errorMessage: null,
            classList: null,
            classDetails: null
        }
    case FETCH_ALL_CLASSES_SUCCESS:
        return {
            ...state,
            isFetching: false,
            classList: action.payload,
            ongoing: action.payload.ongoing,
            upcoming: action.payload.upcoming
        }
    case FETCH_ALL_CLASSES_FAILURE:
        return {
            ...state,
            isFetching: false,
            isError: true,
            errorMessage: action.payload
        }
    case FETCH_ALL_CLASS_DETAILS_REQUEST:
        return {
            ...state,
            isFetching: true,
            isError: false,
            errorMessage: null,
            classDetails: null
        }
    case FETCH_ALL_CLASS_DETAILS_SUCCESS:
        return {
            ...state,
            isFetching: false,
            isError: false,
            errorMessage: null,
            classDetails: action.payload
        }
    case FETCH_ALL_CLASS_DETAILS_FAILURE:
        return {
            ...state,
            isFetching: false,
            isError: true,
            errorMessage: action.payload
        }

    default:
        return state;
  }
}
