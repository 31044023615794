export const PRACTICES_FETCH_REQUEST = 'practices_fetch_request';
export const PRACTICES_FETCH_SUCCESS = 'practices_fetch_success';
export const PRACTICES_FETCH_FAILURE = 'practices_fetch_failure';
export const PRACTICE_FETCH_REQUEST = 'practice_fetch_request';
export const PRACTICE_FETCH_SUCCESS = 'practice_fetch_success';
export const PRACTICE_FETCH_FAILURE = 'practice_fetch_failure';
export const PRACTICE_START_REQUEST = 'practice_start_request';
export const PRACTICE_START_SUCCESS = 'practice_start_success';
export const PRACTICE_START_FAILURE = 'practice_start_failure';
export const PRACTICE_SYNC_DATA_REQUEST = 'practice_sync_data_request';
export const PRACTICE_SYNC_DATA_SUCCESS = 'practice_sync_data_success';
export const PRACTICE_SYNC_DATA_FAILURE = 'practice_sync_data_failure';
export const PRACTICE_SUBMIT_ANSWER_REQUEST = 'practice_submit_answer_request';
export const PRACTICE_SUBMIT_ANSWER_SUCCESS = 'practice_submit_answer_success';
export const PRACTICE_SUBMIT_ANSWER_FAILURE = 'practice_submit_answer_failure';
export const PRACTICE_SUBMIT_REQUEST = 'practice_submit_request';
export const PRACTICE_SUBMIT_SUCCESS = 'practice_submit_success';
export const PRACTICE_SUBMIT_FAILURE = 'practice_submit_failure';
export const PRACTICE_HISTORY_FETCH_REQUEST = 'practice_history_fetch_request';
export const PRACTICE_HISTORY_FETCH_SUCCESS = 'practice_history_fetch_success';
export const PRACTICE_HISTORY_FETCH_FAILURE = 'practice_history_fetch_failure';
export const PRACTICE_RESULT_FETCH_REQUEST = 'practice_result_fetch_request';
export const PRACTICE_RESULT_FETCH_SUCCESS = 'practice_result_fetch_success';
export const PRACTICE_RESULT_FETCH_FAILURE = 'practice_result_fetch_failure';
export const PRACTICE_ANALYTICS_FETCH_REQUEST = 'practice_analytics_fetch_request';
export const PRACTICE_ANALYTICS_FETCH_SUCCESS = 'practice_analytics_fetch_success';
export const PRACTICE_ANALYTICS_FETCH_FAILURE = 'practice_analytics_fetch_failure';
export const QUESTION_ANALYTICS_FETCH_REQUEST = 'question_analytics_fetch_request';
export const QUESTION_ANALYTICS_FETCH_SUCCESS = 'question_analytics_fetch_success';
export const QUESTION_ANALYTICS_FETCH_FAILURE = 'question_analytics_fetch_failure';

export const REPORT_SUBMIT_REQUEST = 'report_submit_request';
export const REPORT_SUBMIT_SUCCESS = 'report_submit_success';
export const REPORT_SUBMIT_FAILURE = 'report_submit_failure';
export const REPORT_ISSUE_REFRESH = 'report_issue_refresh'
