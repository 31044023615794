import {
  USER_FETCH_REQUEST,
  USER_FETCH_SUCCESS,
  USER_FETCH_FAILURE,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  USER_ONBOARDING_REQUEST,
  USER_ONBOARDING_SUCCESS,
  USER_ONBOARDING_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  IMAGE_UPDATE_REQUEST,
  IMAGE_UPDATE_SUCCESS,
  IMAGE_UPDATE_FAILURE,
  PASSWORD_RESET,
  ADD_NEW_PROJECT_REQUEST,
  ADD_NEW_PROJECT_SUCCESS,
  ADD_NEW_PROJECT_FAILURE,
  FETCH_GITHUB_REPO_REQUEST,
  FETCH_GITHUB_REPO_SUCCESS,
  FETCH_GITHUB_REPO_FAILURE,
  CLEAR_UPDATE_ERROR,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILURE,
  FETCH_ALL_SKILLS_REQUEST,
  FETCH_ALL_SKILLS_SUCCESS,
  FETCH_ALL_SKILLS_FAILURE,
  SET_PROJECTS,
  SET_PROFILE_REDIRECT,
  UPDATE_USERNAME_REQUEST,
  UPDATE_USERNAME_SUCCESS,
  UPDATE_USERNAME_FAILURE,
  CLEAR_USERNAME_UPDATE,
  USER_ONBOARDING_FORM_FETCH_REQUEST,
  USER_ONBOARDING_FORM_FETCH_SUCCESS,
  USER_ONBOARDING_FORM_FETCH_FAILURE,
  SET_REDIRECT_TO_ACCOUNT
} from '../constants';

const INITIAL_STATE = {
  user: {},
  isFetching: false,
  isError: false,
  errorMessage: null,
  redirectToAccount: false,
  changePasswordError: null,
  changePasswordSucess: '',
  UploadImageError: false,
  githubRepos: [],
  isRepoFetching: false,
  isRepoError: false,
  errorRepoMessage: null,
  isUpdateError: false,
  updateErrorMessage: null,
  isSkillFetching: false,
  isSkillError: false,
  skillErrorMessage: null,
  skillList: [],
  projects: [],
  redirectToProfile: true,
  isUserNameUpdating: false,
  isUserNameUpdated: false,
  isUserNUpdateError: false,
  uNameerrorMessage: '',
  formFields: null,
  errorFields: {},
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case USER_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.payload,
        redirectToAccount: false
      };
    case USER_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      };
    case USER_UPDATE_REQUEST:
      return {
        ...state,
        isUpdateError: false,
        updateErrorMessage: null
      };
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.payload,
        redirectToAccount: true
      };

    case USER_UPDATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isUpdateError: true,
        updateErrorMessage: action.payload
      };
    case USER_ONBOARDING_REQUEST:
      return {
        ...state,
        isError: false,
        errorMessage: null,
        errorFields: {}
      };

    case USER_ONBOARDING_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.payload,
      };

    case USER_ONBOARDING_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload.errorMessage,
        errorFields: action.payload.errorFields
      };

    case IMAGE_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        UploadImageError: false,
        errorMessage: null

      };
    case IMAGE_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        user: action.payload.data,
        errorMessage: null,
        redirectToAccount: false,
        UploadImageError: false

      };
    case IMAGE_UPDATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        UploadImageError: true,
        errorMessage: action.payload
      };
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        changePasswordSucess: action.payload
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        changePasswordError: action.payload
      };
    case PASSWORD_RESET:
      return {
        ...state,
        changePasswordSucess: '',
        isError: false,
        changePasswordError: null
      };
    case ADD_NEW_PROJECT_REQUEST:
      return {
        ...state,
        isFetching: true,
        isUpdateError: false,
        updateErrorMessage: null
      };
    case ADD_NEW_PROJECT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isUpdateError: false,
        updateErrorMessage: null
      };
    case ADD_NEW_PROJECT_FAILURE:
      return {
        ...state,
        isFetching: false,
        isUpdateError: true,
        updateErrorMessage: action.payload
      };
    case FETCH_GITHUB_REPO_REQUEST:
      return {
        ...state,
        isRepoFetching: true,
        isRepoError: false,
        errorRepoMessage: null,
        githubRepos: []
      };
    case FETCH_GITHUB_REPO_SUCCESS:
      return {
        ...state,
        isRepoFetching: false,
        isRepoError: false,
        errorRepoMessage: null,
        githubRepos: action.payload
      };
    case FETCH_GITHUB_REPO_FAILURE:
      return {
        ...state,
        isRepoFetching: false,
        isRepoError: true,
        errorRepoMessage: action.payload,
        githubRepos: []
      };
    case UPDATE_PROJECT_REQUEST:
      return {
        ...state,
        isFetching: true,
        isUpdateError: false,
        updateErrorMessage: null

      };
    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isUpdateError: false,
        updateErrorMessage: null

      };
    case UPDATE_PROJECT_FAILURE:
      return {
        ...state,
        isFetching: false,
        isUpdateError: true,
        updateErrorMessage: action.payload

      };
    case SET_PROJECTS:
      return {
        ...state,
        projects: action.payload
      };
    case FETCH_ALL_SKILLS_REQUEST:
      return {
        ...state,
        isFetching: true,
        isSkillFetching: true,
        isSkillError: false,
        skillErrorMessage: null,
        skillList: []
      };
    case FETCH_ALL_SKILLS_SUCCESS:
      return {
        ...state,
        isSkillFetching: false,
        isFetching: false,
        isSkillError: false,
        skillErrorMessage: null,
        skillList: action.payload
      };
    case FETCH_ALL_SKILLS_FAILURE:
      return {
        ...state,
        isSkillFetching: false,
        isFetching: false,
        isSkillError: true,
        skillErrorMessage: action.payload,
        skillList: []
      };
    case CLEAR_UPDATE_ERROR:
      return {
        ...state,
        isUpdateError: false,
        updateErrorMessage: null,
        isSkillError: false,
        skillErrorMessage: null,
        isError: false,
        errorMessage: ''
      };
    case SET_PROFILE_REDIRECT:
      return {
        ...state,
        redirectToProfile: action.payload
      };
    case UPDATE_USERNAME_REQUEST:
      return {
        ...state,
        isUserNameUpdating: true,
        isUserNUpdateError: false,
        uNameerrorMessage: '',
        isUserNameUpdated: false

      };
    case UPDATE_USERNAME_SUCCESS:
      return {
        ...state,
        isUserNameUpdating: false,
        isUserNUpdateError: false,
        uNameerrorMessage: '',
        isUserNameUpdated: true
      };
    case UPDATE_USERNAME_FAILURE:
      return {
        ...state,
        isUserNameUpdating: false,
        isUserNameUpdated: false,
        isUserNUpdateError: true,
        uNameerrorMessage: action.payload
      };
    case CLEAR_USERNAME_UPDATE:
      return {
        ...state,
        isUserNameUpdated: false,
        isUserNUpdateError: false,
        uNameerrorMessage: ''
      };

    case USER_ONBOARDING_FORM_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: '',
        formFields: null
      }
    case USER_ONBOARDING_FORM_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        formFields: action.payload
      }
    case USER_ONBOARDING_FORM_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload
      }
    case SET_REDIRECT_TO_ACCOUNT:
      return {
        ...state,
        redirectToAccount: true
      }
    default:
      return state;
  }
}
