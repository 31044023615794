import {
  PRACTICE_HISTORY_FETCH_REQUEST,
  PRACTICE_HISTORY_FETCH_SUCCESS,
  PRACTICE_HISTORY_FETCH_FAILURE,
  PRACTICE_RESULT_FETCH_REQUEST,
  PRACTICE_RESULT_FETCH_SUCCESS,
  PRACTICE_RESULT_FETCH_FAILURE
} from '../constants';

const INITIAL_STATE = {
  all: [],
  selectedPractice: null,
  isFetching: false,
  isError: false,
  errorMessage: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
  case PRACTICE_HISTORY_FETCH_REQUEST:
    return {
      ...state,
      isFetching: true
    };
  case PRACTICE_HISTORY_FETCH_SUCCESS:
    return {
      ...state,
      isFetching: false,
      all: action.payload.data,
      isError: false,
      errorMessage: ''
    };
  case PRACTICE_HISTORY_FETCH_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    };
  case PRACTICE_RESULT_FETCH_REQUEST:
    return {
      ...state,
      isFetching: true,
      selectedPractice: null
    };
  case PRACTICE_RESULT_FETCH_SUCCESS:
    return {
      ...state,
      isFetching: false,
      selectedPractice: action.payload.data
    };
  case PRACTICE_RESULT_FETCH_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    };
  default:
    return state;
  }
}
