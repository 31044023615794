import {
  QUESTION_ANALYTICS_FETCH_REQUEST,
  QUESTION_ANALYTICS_FETCH_SUCCESS,
  QUESTION_ANALYTICS_FETCH_FAILURE
} from '../constants';

const INITIAL_STATE = {
  analytics: null,
  isFetching: false,
  isError: false,
  errorMessage: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
  case QUESTION_ANALYTICS_FETCH_REQUEST:
    return {
      ...state,
      isFetching: true,
      isError: false
    };
  case QUESTION_ANALYTICS_FETCH_SUCCESS:
    return {
      ...state,
      isFetching: false,
      isError: false,
      errorMessage: '',
      analytics: action.payload.data
    };
  case QUESTION_ANALYTICS_FETCH_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      analytics: null,
      errorMessage: action.payload
    };
  default:
    return state;
  }
}
