const INITIAL_STATE = {
  Result: '',
  Compiler: '',
  isFetching: false,
  resultarr: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case 'CODE_EVALUATION_SUCCESS':
    return {
      ...state,
      isFetching: false,
      Result: action.payload.stdout,
      Compiler: action.payload.compile_output,
      resultarr: [...state.resultarr, { judge0Details: action.payload.config, output: action.payload.stdout, timestamp: Date() }]
    };
  case 'START_EVAL_REQUEST':
    return {
      ...state,
      isFetching: true
    };
  case 'CLEAR_OUTPUT':
    return {
      ...state,
      Result: '',
      Compiler: ''
    };
  default:
    return state;
  }
};
