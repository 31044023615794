import {
  PRACTICE_START_REQUEST,
  PRACTICE_START_SUCCESS,
  PRACTICE_START_FAILURE,
  PRACTICE_SYNC_DATA_REQUEST,
  PRACTICE_SYNC_DATA_SUCCESS,
  PRACTICE_SYNC_DATA_FAILURE,
  PRACTICE_SUBMIT_ANSWER_REQUEST,
  PRACTICE_SUBMIT_ANSWER_SUCCESS,
  PRACTICE_SUBMIT_ANSWER_FAILURE,
  PRACTICE_SUBMIT_REQUEST,
  PRACTICE_SUBMIT_SUCCESS,
  PRACTICE_SUBMIT_FAILURE
} from '../constants';

const INITIAL_STATE = {
  runTimer: true,
  answeredQuestion: null,
  practicePaper: null,
  shouldStartPractice: false,
  isFetching: false,
  isError: false,
  errorMessage: null,
  submittedPaper: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
  case PRACTICE_START_REQUEST:
    return {
      ...state,
      isFetching: true
    };
  case PRACTICE_START_SUCCESS:
    return {
      ...state,
      isFetching: false,
      shouldStartPractice: true
    };
  case PRACTICE_START_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    };
  case PRACTICE_SYNC_DATA_REQUEST:
    return {
      ...state,
      isFetching: true
    };
  case PRACTICE_SYNC_DATA_SUCCESS:
    return {
      ...state,
      practicePaper: action.payload.data,
      isFetching: false,
      runTimer: true
    };
  case PRACTICE_SYNC_DATA_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    };
  case PRACTICE_SUBMIT_ANSWER_REQUEST:
    return {
      ...state,
      isFetching: true,
      runTimer: false
    };
  case PRACTICE_SUBMIT_ANSWER_SUCCESS:
    return {
      ...state,
      practicePaper: action.payload.data,
      isFetching: false,
      runTimer: false
    };
  case PRACTICE_SUBMIT_ANSWER_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload,
      runTimer: false
    };
  case PRACTICE_SUBMIT_REQUEST:
    return {
      ...state,
      isFetching: true
    };
  case PRACTICE_SUBMIT_SUCCESS:
    return {
      ...state,
      submittedPaper: action.payload.data,
      isFetching: false
    };
  case PRACTICE_SUBMIT_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    };
  default:
    return state;
  }
}
