import {
  SUBJECTS_FETCH_REQUEST,
  SUBJECTS_FETCH_SUCCESS,
  SUBJECTS_FETCH_FAILURE
} from '../constants';

const INITIAL_STATE = {
  availableSubjects: null,
  availableSubjectIds: [],
  isFetching: false,
  isError: false,
  errorMessage: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case SUBJECTS_FETCH_REQUEST:
    return {
      ...state,
      isFetching: true,
      isError: false,
      errorMessage: null
    };
  case SUBJECTS_FETCH_SUCCESS:
    return {
      ...state,
      isFetching: false,
      isError: false,
      availableSubjects: action.payload.subjects,
      availableSubjectIds: action.payload.subjectIds
    };
  case SUBJECTS_FETCH_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    };
  default:
    return state;
  }
};
