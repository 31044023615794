import {
  FETCH_USER_INTERESTS_REQUEST,
  FETCH_USER_INTERESTS_SUCCESS,
  FETCH_USER_INTERESTS_FAILURE,
  ADD_NEW_INTEREST_REQUEST,
  ADD_NEW_INTEREST_SUCCESS,
  ADD_NEW_INTEREST_FAILURE,
  CLEAR_ERROR,
  CLEAR_INTERESTS
} from '../constants';

const INITIAL_STATE = {
  isFetching: false,
  isError: false,
  errorMessage: '',
  skills: [],
  likedSkills: [],
  isAdding: false,
  isAddingError: false,
  addErrorMessage: ''
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
  case FETCH_USER_INTERESTS_REQUEST:
    return {
      ...state,
      isFetching: true,
      isError: false,
      errorMessage: '',
      skills: [],
      likedSkills: []
    };
  case FETCH_USER_INTERESTS_SUCCESS:
    return {
      ...state,
      isFetching: false,
      isError: false,
      errorMessage: '',
      skills: action.payload.skills,
      likedSkills: action.payload.likedSkills
    };
  case FETCH_USER_INTERESTS_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload,
      skills: [],
      likedSkills: []
    };
  case ADD_NEW_INTEREST_REQUEST:
    return {
      ...state,
      isAdding: false,
      isAddingError: false,
      addErrorMessage: ''
    };
  case ADD_NEW_INTEREST_SUCCESS:
    return {
      ...state,
      isAdding: false,
      isAddingError: false,
      addErrorMessage: ''
    };
  case ADD_NEW_INTEREST_FAILURE:
    return {
      ...state,
      isAdding: false,
      isAddingError: true,
      addErrorMessage: action.payload
    };
  case CLEAR_ERROR:
    return {
      ...state,
      isAddingError: false,
      addErrorMessage: ''
    };
  case CLEAR_INTERESTS:
    return INITIAL_STATE;
  default:
    return state;
  }
}
