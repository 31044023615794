import {
  REPORT_SUBMIT_REQUEST,
  REPORT_SUBMIT_SUCCESS,
  REPORT_SUBMIT_FAILURE,
  REPORT_ISSUE_REFRESH
} from '../constants';

const INITIAL_STATE = {
  report: null,
  isFetching: false,
  isError: false,
  errorMessage: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
  case REPORT_SUBMIT_REQUEST:
    return {
      ...state,
      isFetching: true,
      isError: false,
      report: null
    };
  case REPORT_SUBMIT_SUCCESS:
    return {
      ...state,
      isFetching: false,
      isError: false,
      errorMessage: '',
      report: action.payload.data
    };
  case REPORT_SUBMIT_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload,
      report: null
    };

  case REPORT_ISSUE_REFRESH:
    return {
      ...state,
      report: null,
      isFetching: false,
      isError: false,
      errorMessage: null
    };
  default:
    return state;
  }
}
