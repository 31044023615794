export const USER_FETCH_REQUEST = 'user_fetch_request';
export const USER_FETCH_SUCCESS = 'user_fetch_success';
export const USER_FETCH_FAILURE = 'user_fetch_failure';
export const USER_UPDATE_REQUEST = 'user_update_request';
export const USER_UPDATE_SUCCESS = 'user_update_success';
export const USER_UPDATE_FAILURE = 'user_update_failure';
export const CHANGE_PASSWORD_REQUEST = 'change_password_request';
export const CHANGE_PASSWORD_SUCCESS = 'change_password_success';
export const CHANGE_PASSWORD_FAILURE = 'change_password_failure';
export const IMAGE_UPDATE_REQUEST = 'image_update_request';
export const IMAGE_UPDATE_SUCCESS = 'image_update_success';
export const IMAGE_UPDATE_FAILURE = 'image_update_failure';
export const PASSWORD_RESET = 'password_reset';
export const SET_PROJECTS = 'set_projects';
export const ADD_NEW_PROJECT_REQUEST = 'add_new_project_request';
export const ADD_NEW_PROJECT_SUCCESS = 'add_new_project_success';
export const ADD_NEW_PROJECT_FAILURE = 'add_new_project_failure';

export const UPDATE_PROJECT_REQUEST = 'update_project_request';
export const UPDATE_PROJECT_SUCCESS = 'update_project_success';
export const UPDATE_PROJECT_FAILURE = 'update_project_failure';

export const SET_REDIRECT_TO_ACCOUNT = "set_redirect_to_account";


export const FETCH_GITHUB_REPO_REQUEST = 'fetch_github_repo_request';
export const FETCH_GITHUB_REPO_SUCCESS = 'fetch_github_repo_success';
export const FETCH_GITHUB_REPO_FAILURE = 'fetch_github_repo_failure';

export const FETCH_ALL_SKILLS_REQUEST = 'fetch_all_skills_request';
export const FETCH_ALL_SKILLS_SUCCESS = 'fetch_all_skills_success';
export const FETCH_ALL_SKILLS_FAILURE = 'fetch_all_skills_failure';


export const CLEAR_UPDATE_ERROR = 'clear_update_error';

export const SET_PROFILE_REDIRECT = 'set_profile_redirect';


export const UPDATE_USERNAME_REQUEST = 'update_username_request';
export const UPDATE_USERNAME_SUCCESS = 'update_username_success';
export const UPDATE_USERNAME_FAILURE = 'update_username_failure';

export const CLEAR_USERNAME_UPDATE = 'clear_username_update';

export const USER_ONBOARDING_FORM_FETCH_REQUEST = "user_onboarding_form_fetch_request";
export const USER_ONBOARDING_FORM_FETCH_SUCCESS = "user_onboarding_form_fetch_success";
export const USER_ONBOARDING_FORM_FETCH_FAILURE = "user_onboarding_form_fetch_failure";

export const USER_ONBOARDING_REQUEST = 'user_onboarding_request';
export const USER_ONBOARDING_SUCCESS = 'user_onboarding_success';
export const USER_ONBOARDING_FAILURE = 'user_onboarding_failure';


