import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: 2,
  zIndex: 9999,
  backgroundColor: 'rgb(255, 255, 255, 0.4)'
};
function Progress(props) {
  const { isActive } = props;

  if (isActive) {
    return <LinearProgress color="secondary" style={styles} />;
  }
  return <span />;
}

Progress.propTypes = {
  isActive: PropTypes.bool.isRequired
};
function mapStateToProps(state) {
  return {
    isActive: state.progress.isActive
  };
}
export default connect(mapStateToProps, null)(Progress);
