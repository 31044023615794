export const FETCH_ALL_CLASSES_REQUEST = "fetch_all_classes_request";
export const FETCH_ALL_CLASSES_SUCCESS = "fetch_all_classes_success";
export const FETCH_ALL_CLASSES_FAILURE = "fetch_all_classes_failure";

export const FETCH_ALL_CLASS_DETAILS_REQUEST = "fetch_all_class_details_request";
export const FETCH_ALL_CLASS_DETAILS_SUCCESS = "fetch_all_class_details_success";
export const FETCH_ALL_CLASS_DETAILS_FAILURE = "fetch_all_class_details_failure";

export const FETCH_CLASS_STATUS_REQUEST = "fetch_class_status_request";
export const FETCH_CLASS_STATUS_SUCCESS = "fetch_class_status_success";
export const FETCH_CLASS_STATUS_FAILURE = "fetch_class_status_failure";

export const UPDATE_ATTENDENCE_REQUEST = "update_attendence_request";
export const UPDATE_ATTENDENCE_SUCCESS = "update_attendence_success";
export const UPDATE_ATTENDENCE_FAILURE = "update_attendence_failure";