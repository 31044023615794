export const NOTIFICATION_FETCH_REQUEST = 'notification_fetch_request';
export const NOTIFICATION_FETCH_SUCCESS = 'notification_fetch_success';
export const NOTIFICATION_FETCH_FAILURE = 'notification_fetch_failure';
export const NOTIFICATION_UPDATE_REQUEST = 'notification_update_request';
export const NOTIFICATION_UPDATE_SUCCESS = 'notification_update_success';
export const NOTIFICATION_UPDATE_FAILURE = 'notification_update_failure';
export const NOTIFICATION_COUNT_REQUEST = 'notification_count_request';
export const NOTIFICATION_COUNT_SUCCESS = 'notification_count_success';
export const NOTIFICATION_COUNT_FAILURE = 'notification_count_failure';
export const SHOW_NEW_NOTIFICATION = 'show_new_notification';
export const HIDE_NEW_NOTIFICATION = 'hide_new_notification';
