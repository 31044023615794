import {
  FEEDBACK_SUBMIT_REQUEST,
  FEEDBACK_SUBMIT_SUCCESS,
  FEEDBACK_SUBMIT_FAILURE,
  FEEDBACK_RESET,
  CLEAR_FEEDBACK_ERROR
} from '../constants';


const INITIAL_STATE = {
  feedback: null,
  isFetching: false,
  isError: false,
  errorMessage: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
  case FEEDBACK_SUBMIT_REQUEST:
    return {
      ...state,
      isFetching: true
    };
  case FEEDBACK_SUBMIT_SUCCESS:
    return {
      ...state,
      isFetching: false,
      feedback: action.payload.data
    };
  case FEEDBACK_SUBMIT_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    };

  case FEEDBACK_RESET:
    return {
      ...state,
      feedback: null,
      isError: false,
      errorMessage: null
    };
  case CLEAR_FEEDBACK_ERROR:
    return {
      ...state,
      isError: false,
      errorMessage: null
    };
  default:
    return state;
  }
}
