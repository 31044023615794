import {
  TEST_HISTORY_FETCH_REQUEST,
  TEST_HISTORY_FETCH_SUCCESS,
  TEST_HISTORY_FETCH_FAILURE,
  TEST_RESULT_FETCH_REQUEST,
  TEST_RESULT_FETCH_SUCCESS,
  TEST_RESULT_FETCH_FAILURE,
  TEST_ATTEMPT_FETCH_REQUEST,
  TEST_ATTEMPT_FETCH_SUCCESS,
  TEST_ATTEMPT_FETCH_FAILURE,
  TEST_NOT_ATTEMPTED_EARLIER,
  TEST_SUMMARY_FETCH_REQUEST,
  TEST_SUMMARY_FETCH_SUCCESS,
  TEST_SUMMARY_FETCH_FAILURE
} from '../constants';

const INITIAL_STATE = {
  all: [],
  selectedTest: null,
  attemptedTest: null,
  isAttemptedTest: false,
  isFetching: false,
  isError: false,
  errorMessage: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case TEST_HISTORY_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case TEST_HISTORY_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        all: action.payload.data
      };
    case TEST_HISTORY_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload,
        all: []
      };
    case TEST_RESULT_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        selectedTest: null
      };
    case TEST_RESULT_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        selectedTest: action.payload.data.data.attributes
      };
    case TEST_RESULT_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload,
        selectedTest: null
      };
    case TEST_ATTEMPT_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAttemptedTest: false,
        attemptedTest: null,
        isError: false,
        errorMessage: null
      };
    case TEST_ATTEMPT_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAttemptedTest: true,
        attemptedTest: action.payload.data,
        isError: false,
        errorMessage: null
      };
    case TEST_NOT_ATTEMPTED_EARLIER:
      return {
        ...state,
        isFetching: false,
        isAttemptedTest: false,
        attemptedTest: null,
        isError: false,
        errorMessage: null
      };
    case TEST_ATTEMPT_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload,
        attemptedTest: null
      };
    case TEST_SUMMARY_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false
      };
    case TEST_SUMMARY_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        selectedTest: action.payload.data.data
      };
    case TEST_SUMMARY_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        errorMessage: action.payload,
        selectedTest: null
      };

    default:
      return state;
  }
}
