import {
  WP_TANDC_REQUEST,
  WP_TANDC_SUCCESS,
  WP_TANDC_FAILURE
} from '../constants';

const INITIAL_STATE = {
  termsAndConditions: null,
  isFetching: false,
  isError: false,
  errorMessage: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
  case WP_TANDC_REQUEST:
    return {
      ...state,
      isFetching: true,
      isError: false
    };
  case WP_TANDC_SUCCESS:
    return {
      ...state,
      isFetching: false,
      isError: false,
      termsAndConditions: action.payload.data[0]
    };
  case WP_TANDC_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    };
  default:
    return state;
  }
}
