import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      ...blue,
      20: '#dadbfd',
      50: '#e3f2fd',
      100: '#bbdefb',
      200: '#90caf9',
      300: '#64b5f6',
      400: '#42a5f5',
      500: '#1a73e8',
      600: '#1e88e5',
      700: '#1976d2',
      800: '#1565c0',
      900: '#0d47a1',
      A100: '#8c9eff',
      A200: '#536dfe',
      A400: '#3d5afe',
      A700: '#304ffe'
    },
    secondary: {
      ...orange
    },
    background: {
      default: '#f9f9f9'
    }
  },
  typography: {
    fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    useNextVariants: true
  },
  // Overriding default components
  overrides: {
    // Card component overrides
    MuiButton: {
      root: {
        padding: '8px 24px',
        textTransform: 'none'
      }
    },
    MuiCardHeader: {
      root: {
        padding: '2px 12px',
        borderBottom: '1px solid #e9e9e9'
      },
      title: {
        color: '#212121',
        fontSize: 17,
        lineHeight: '1.31429em'
      },
      subheader: {
        color: '#757575',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '1.21429em'
      },
      avatar: {
        marginRight: 5
      }
    }
  },
  // Scrollbar UI
  scrollableY: {
    overflowY: 'auto',
    outlineOffset: '-2px',
    '&:hover': {
      overflowY: 'auto'
    },
    '&::-webkit-scrollbar': {
      width: 5
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#DFDFDF'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f0f0f0'
    }
  },
  // Custom for custom components
  card: {
    position: 'relative',
    borderRadius: 8,
    boxShadow: '0 1px 7px rgba(0,0,0,.05)',
    marginBottom: 12
  },
  cardHeader: {
    padding: '10px 18px',
    border: 'none'
  },
  cardActions: {
    padding: 0,
    borderTop: '1px solid #ecedef'
  },
  cardSingleActionButton: {
    width: '100%',
    justifyContent: 'flex-start',
    padding: '10px 12px 12px 77px',
    fontSize: '0.915rem',
    borderRadius: '0px 0px 8px 8px'
  },
  cardHeaderAvatar: {
    marginRight: 20
  },
  cardHeaderTitle: {
    color: 'rgba(0,0,0,.66)',
    fontSize: 12,
    marginBottom: 4
  },
  cardHeaderSubheader: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1rem'
  },
  cardHeaderAction: {
    fontSize: 13,
    color: 'rgba(0,0,0,.56)',
    marginTop: 0,
    marginRight: 0
  },
  lineCard: {
    boxShadow: 'none',
    border: '1px solid #dadce0',
    borderRadius: 8
  },
  lineCardContent: {
    padding: '20px 30px'
  },
  lineButton: {
    // color: '#5c5dfa',
    backgroundColor: '#FFF',
    borderRadius: 20,
    border: '1px solid #DADCE0',
    boxShadow: 'none',
    // fontSize: '0.775rem',
    transition: '.2s',
    textTransform: 'none',
    height: 25,
    color: '#3C4043',
    fontSize: '0.85rem',
    '&:hover': {
      backgroundColor: '#E8F0FD',
      border: '1px solid #E8F0FD',
      color: '#1a73e8'
    }
  },
  contentWrapper: {
    flexGrow: 1,
    width: '100%',
    margin: 0
  },
  authContentWrapper: {
    flexGrow: 1,
    width: '100%',
    margin: 0,
    overflowX: 'hidden',
    overflowY: 'hidden',
    height: '100vh'
  },
  authLeftContainer: {
    padding: '20px 50px 10px 50px',
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100vh'
  },
  authRightContainer: {
    padding: 50,
    overflowX: 'hidden',
    overflowY: 'hidden',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    boxShadow: 'inset 3px 0 10px -7px rgba(0,0,0,.3)',
    // backgroundColor: '#4a4bfa',
    backgroundColor: '#f6f6f6'
  },
  rainbowText: {
    '& span': {
      display: 'block',
      '-webkit-text-fill-color': 'transparent !important',
      '-webkit-background-clip': 'text !important',
      background: '-webkit-linear-gradient(left , rgb(33, 212, 253) 12% , rgb(183, 33, 255) 88%)',
      color: 'rgb(33, 212, 253) !important',
      fontSize: '29px !important',
      fontWeight: '500'
    }
  },
  singleBreadcrumbContainer: {
    paddingTop: 64,
    paddingBottom: 4,
    borderBottom: '1px solid #dadce0',
    marginBottom: 24
  },
  breadcrumbFabButton: {
    background: 'transparent',
    boxShadow: 'none',
    color: 'rgba(0, 0, 0, 0.65)',
    fontSize: '1.6rem',
    textTransform: 'none',
    fontWeight: '500',
    paddingLeft: 0,
    '&:hover': {
      background: 'transparent !important',
      boxShadow: 'none'
    },
    '&:focus': {
      background: 'transparent !important',
      boxShadow: 'none'
    }
  },
  breadcrumbFabIcon: {
    color: '#5f6368',
    marginRight: 16
  },
  actionButton: {
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#1565c0'
    }
  }
});

export default theme;
