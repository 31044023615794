import {
  ADD_TOAST,
  REMOVE_TOAST
} from '../constants';

const INITIAL_STATE = {
  all: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
  case ADD_TOAST:
    return {
      ...state,
      all: [...state.all, action.payload]
    };
  case REMOVE_TOAST:
    return {
      ...state,
      all: state.all.filter(item => item.id !== action.payload)
    };
  default:
    return state;
  }
}
