import {
  AUTH_USER_REQUEST,
  AUTH_USER_SUCCESS,
  AUTH_USER_FAILURE,
  SIGNIN_USER_FORM_RESET,
  INVITE_USER_FORM_RESET,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  INVITE_USER_FAILURE,
  ACTIVATE_MEMBERSHIP_REQUEST,
  ACTIVATE_MEMBERSHIP_SUCCESS,
  ACTIVATE_MEMBERSHIP_FAILURE,
  AUTH_USER,
  UNAUTH_USER,
  ACCEPT_INVITATION_REQUEST,
  ACCEPT_INVITATION_SUCCESS,
  ACCEPT_INVITATION_FAILURE,
  FORGOT_PASSWORD_FORM_RESET,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_FAILURE
} from '../constants';

const INITIAL_STATE = {
  authenticated: false,
  isFetching: false,
  isError: false,
  signinError: null,
  inviteSuccess: false,
  invitation: null,
  inviteErrorMessage: '',
  membershipSuccess: false,
  membership: null,
  membershipErrorMessage: '',
  forgotPasswordError: null,
  forgotPasswordProcessed: false,
  invitationError: null,
  resetPasswordError: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH_USER_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case AUTH_USER_SUCCESS:
      return {
        ...state,
        authenticated: true,
        isFetching: false,
        signinError: null
      };
    case AUTH_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        signinError: action.payload
      };
    case SIGNIN_USER_FORM_RESET:
      return {
        isError: false,
        signinError: null
      };
    case INVITE_USER_FORM_RESET:
      return {
        ...state,
        isError: false,
        inviteSuccess: false,
        invitation: null,
        inviteErrorMessage: ''
      };
    case INVITE_USER_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case INVITE_USER_SUCCESS:
      return {
        ...state,
        inviteSuccess: true,
        invitation: action.payload.data,
        isFetching: false,
        isError: false,
        inviteErrorMessage: ''
      };
    case INVITE_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        inviteErrorMessage: action.payload
      };
    case ACTIVATE_MEMBERSHIP_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case ACTIVATE_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        membershipSuccess: true,
        membership: action.payload.data,
        isFetching: false,
        isError: false,
        membershipErrorMessage: ''
      };
    case ACTIVATE_MEMBERSHIP_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        membershipErrorMessage: action.payload
      };
    case AUTH_USER:
      return {
        ...state,
        authenticated: true
      };
    case UNAUTH_USER:
      return {};
    case ACCEPT_INVITATION_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case ACCEPT_INVITATION_SUCCESS:
      return {
        ...state,
        authenticated: true,
        isFetching: false
      };
    case ACCEPT_INVITATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        invitationError: action.payload
      };
    case FORGOT_PASSWORD_FORM_RESET:
      return {
        isError: false,
        forgotPasswordError: null,
        forgotPasswordProcessed: false
      };
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        forgotPasswordProcessed: true
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        forgotPasswordError: action.payload
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        resetPasswordError: action.payload
      };
    default:
      return state;
  }
}
